.home{
    width: 100%;
}
.hero{
    width: 100%;
    height: 100vh;
    position: relative;
    top: 0px;
}
.hero .video{
    position: absolute;
    width: 100%; 
    height: 100%;
    object-fit: fill;
}
.hero .video2{
    position: absolute;
    width: 100%; 
    height: 100%;
    object-fit: fill;
    display: none;
}

.hero2{
    width: 100%;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 60px;
    margin-top: 50px;
}
.hero2 .hero2Container{
    width: 90%;
}
.hero2 .hero2Container p{
    color: black;
    font-size: 20px;
    font-family: 'Century Gothic';
    letter-spacing: 1px;
}
.hero2 .hero2Container .p2{
    color: rgb(59, 59, 59);
    font-size: 16px;
    margin-top: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 23px;
}
.hero2 .hero2Container h3{
    color: rgb(62, 62, 62);
    font-size: 50px;
    font-family: 'Century Gothic';
    letter-spacing: 1px;
    width: 80%;
    margin-top: 5px;
}
.hero2 .hero2Container .quality{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
}
.hero2 .hero2Container .quality .box{
    width: 22%;
    height: 300px;
    border: 1px solid rgb(219, 219, 219);
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.hero2 .hero2Container .quality .box .boxContent .img2{
    width: 60px;
    display: none;
}
.hero2 .hero2Container .quality .box .boxContent .img{
    width: 60px;
}
.hero2 .hero2Container .quality .box:hover{
    background-color: orange;
}
.hero2 .hero2Container .quality .box:hover .boxContent h2{
    color: rgb(255, 255, 255);
}
.hero2 .hero2Container .quality .box:hover .boxContent .img{
    display: none;
}

.hero2 .hero2Container .quality .box:hover .boxContent .img2{
    display: block;
}
.hero2 .hero2Container .quality .box .boxContent{
    width: 100%;
    margin-top: 50px;
}
.hero2 .hero2Container .quality .box .boxContent h2{
    font-family: "Century Gothic";
    margin-top: 25px;
    color: orange;
    font-size: 18px;
}

.hero2 .hero2Container button{
    border: 2px solid rgb(49, 49, 49);
    background-color: white;
    color: rgb(49, 49, 49);
    transition: 0.2s ease-in-out;
    width: 250px;
    height: 43px;
    font-family: 'Century Gothic';
    margin-top: 20px;
    font-weight: 600;
    font-size: 17px;
}
.heroTitle button{
    border: 2px solid rgb(49, 49, 49);
    background-color: rgba(255, 255, 255, 0.322);
    color: rgb(49, 49, 49);
    transition: 0.2s ease-in-out;
    width: 250px;
    height: 43px;
    font-family: 'Century Gothic';
    margin-top: 20px;
    font-weight: 600;
    font-size: 17px;
}

.hero2 .hero2Container button:hover{
    border: 2px solid rgb(49, 49, 49);
    background-color:rgb(49, 49, 49);
    color: white;
    transition: 0.2s ease-in-out;
    font-family: 'Century Gothic';
    margin-top: 20px;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}

.projects .projectsContainer .image{
    width: 46%;
    height: 270px;
    margin-bottom: 15px;
    box-shadow: 2px 2px 5px rgb(172, 172, 172);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
.projects .projectsContainer .image img{
    width: 100%;
    height: 270px;
    border-radius: 5px;
    position: absolute;
    transition:1s ease-in-out;
    object-fit: fill;
}
.projects .projectsContainer .image img:hover{
    border-radius: 5px;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    transform: scale(1.1);
}

.projects .projectsContainer2 .image{
    width: 350px;
    height: 300px;
    margin-bottom: 15px;
    box-shadow: 2px 2px 5px rgb(172, 172, 172);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
.projects .projectsContainer2 .image img{
    width: 100%;
    height: 300px;
    border-radius: 5px;
    position: absolute;
    transition:1s ease-in-out;
    object-fit: fill;
}
.projects .projectsContainer2 .image img:hover{
    border-radius: 5px;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    transform: scale(1.1);
}


.title .texting{
    padding-left: 20px;
    padding-right: 20px;

}
.title .texting p{
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(77, 77, 77);
    
}
.title .texting .text{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 50px);
}
.title .texting .text img{
    width: 400px;
    height: 190px;
    border-radius: 5px;
    margin-right: 0px;
    object-fit: cover;
}
.title .texting .text p{
    font-size: 18px;
    margin-bottom: 5px;
}

.title h3{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 35px;
    color: rgb(75, 75, 75);
    background-color: rgb(255, 255, 255);
    width: 100%;
    box-shadow: 2px 2px 5px rgb(215, 215, 215);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Century Gothic';
    border: 1px solid orange;
    border-left: none;
    border-right: none;


}
.part{
    width: 100%;
    margin-bottom: 80px;
    display: block;
}
.part .secondVid{
    display: none;
}
.part .secondImg{
    display: flex;
    justify-content: center;
}
.part img{
    height: 400px;
    object-fit: cover;
    width: 90%;
    border-radius: 10px;

}
.title .line{
    margin-bottom: 40px;
}
.projects{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.projectsContainer{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.projectsContainer2{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.porject{
    width: 420px;
    background-color: white;
    border-radius: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-bottom: 20px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 6px rgb(198, 198, 198);
    margin: 10px;
    border: 1px solid rgb(181, 209, 255);
}
.projects .porject .projectImg{
    width: 100%;
    height: 349px;
    background-color: rgb(205, 205, 205);
    position: relative;
    margin-bottom: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.projects .porject .projectImg:hover{
    cursor: pointer;
}
.projects .porject .projectImg img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.projects .porject .projectImg .price{
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgb(189, 189, 189);
    width: 150px;
    height: 45px;
    padding-left: 5px;
    padding-top: 5px;
    display: none;
}

.porject .informations{
    width: 95%;
    margin-left: 2.5%;
    margin-top: 5px;
}
.porject .informations .header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5px;
}
.porject .informations .desc{
    margin-top: 10px;
    color: gray;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.porject button{
    width: 100%;
    height: 35px;
    border-radius: 100px;
    margin-top: 20px;
    border: 1px solid rgb(0, 98, 255);    
    color: rgb(0, 98, 255) ;
}
.porject button:hover{
    width: 100%;
    height: 35px;
    border-radius: 100px;
    margin-top: 20px;
    border: none;  
    background-color: rgb(200, 200, 200);  
    cursor: pointer;
}
.porject .icons{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
}
.porject .icons .social{
    display: flex;
    width: 100px;
}
.porject .icons .social .icon{
    background-color: rgb(220, 220, 220);
    border-radius: 100px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}
.porject .icons .social .icon:hover{
    background-color: rgb(0, 179, 255);
}
.porject .icons .social img{
    width: 25px;
    height: 25px;
}
.porject .icons .qualite{
    display: flex;
    align-items: center;
}
.porject .icons .qualite img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
}
.porject .icons .qualite p{
    font-size: 18px;
    color: rgb(86, 86, 86);
}



.forms{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    background-color: orange;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding-top: 0px;
    background-image: url("../assets/footer.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}
.formContainer{
    width: 100%;
    margin-bottom: 0px;
    background-color: rgba(0, 0, 0, 0.63); 
    padding-bottom: 20px;

}
.contactTitle{
    font-family: 'Century Gothic';
    margin-left: 120px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(217, 217, 217);
}

.formBox{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.formContainer h2{
    font-size: 30px;
    font-weight: 800;
    font-family: 'Century Gothic';
    width: 90%;
    margin-left: 9%;
    margin-bottom: 30px;

}
.formBox form{
    background-color: rgba(255, 255, 255, 0.534);
    width: 400px;
    padding-top: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px rgb(87, 87, 87);
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.formBox form .frm{
    width: 100%;
    padding-left: 15px;
}
.formBox form .frm h4{
    margin-bottom: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 22px;
}
.formBox form input{
    display: block;
    width: calc(100% - 40px);
    height: 26px;
    margin-bottom: 15px;
    padding-left: 5px;
    outline: none;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.659);

}
.formBox form .frm button{
    width: 92%;
    border-radius: 100px;
    border: 1px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    background-color: rgb(0, 102, 255);
    margin-left: -30px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
}

.formBox form p{
    margin-bottom: 5px;
}
.frm img{
    width: 35px;
    margin-right: 30px;
}
.message{
    width: 300px;
    height: 40px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 6px rgb(202, 202, 202);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: green;
    margin-left: -20px;
    margin-top: 10px;

}
.message img{
    width: 30px;
    margin-right: 10px;
}
.part1{
    display: none;
}
.maps{
    display: flex;
    justify-content: center;
}
.home .hero .heroTitle{
    position: absolute;
    top: 50%;
    font-family: 'Century Gothic';
    color: white;
    width: 80%;
    left: 20px;
    max-width: 650px;
    z-index: 50;
}
.home .hero .heroTitle h1{
    text-shadow: 1px 1px 2px rgb(45, 45, 45);
    font-size: 50px;
    width: 100%;
    font-weight: 400;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.home .hero .heroTitle .bigTitle{
    text-shadow: 1px 1px 2px rgb(45, 45, 45);
    position: relative;
    top: -20px;
    font-size: 55px;
}
.home .hero .cadre{
    position: absolute;
    top: 35%;
    height: 360px;
    width: 650px;
    border: 20px solid rgb(62, 62, 255);
    border-left: none;
    border-top-right-radius: 100px; 
    border-bottom-right-radius: 5px;

}
.home .hero .upperHero{
    position: absolute;
    width: 100%; 
    height: 110%;
    background-color: rgba(185, 185, 185, 0.16);
}
.secondVid{
    width: 100%;
}
.secondVid video{
    width: 100%;
}
.sociaux{
    width: 100%;
    display: flex;
    justify-content: center;
}
.sociaux .sociauxContainer{
    width: 82%;
    margin-top: 20px;
}
.sociaux .sociauxContainer h2{
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 0;
    margin-bottom: 20px;
}
.sociaux .sociauxContainer .so{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    text-decoration: none;
}
.sociaux .sociauxContainer .so img{
    width: 30px;
    margin-right: 10px;
}
.sociaux .sociauxContainer .so p{
    color: rgb(214, 214, 214);
    font-family: sans-serif;
    font-size: 17px;
}
.rights p{
    color: white;
    margin-top: 35px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
@media screen and (max-width:880px) {
    .title .texting .text{
        display: block;
    }
    .title .texting .text img{
        margin-top: 20px;
    }
    .projects .projectsContainer .image{
        width: 100%;
        height: 250px;
    }
    .hero2 .hero2Container .quality{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
    }
    .hero2 .hero2Container .quality .box{
        width: 30%;
        height: 300px;
        border: 1px solid rgb(200, 200, 200);
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }
    .hero2 .hero2Container .quality .box .boxContent .img2{
        width: 80px;
        display: none;
    }
    .hero2 .hero2Container .quality .box .boxContent .img{
        width: 80px;
    }
}
@media screen and (max-width:750px) {
    .hero2 .hero2Container .quality{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
    }
    .hero2 .hero2Container .quality .box{
        width: 30%;
        height: 250px;
        border: 1px solid rgb(200, 200, 200);
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }
    .hero2 .hero2Container .quality .box .boxContent .img2{
        width: 70px;
        display: none;
    }
    .hero2 .hero2Container .quality .box .boxContent .img{
        width: 70px;
    }
    .hero2 .hero2Container .quality .box .boxContent h2{
        font-family: "Century Gothic";
        margin-top: 25px;
        color: orange;
        font-size: 18px;
    }
    
    .hero2 .hero2Container .quality .box:hover .boxContent h2{
        color: rgb(255, 255, 255);
        font-size: 18px;
    }
    .hero2 .hero2Container h3{
        color: rgb(62, 62, 62);
        font-size: 40px;
        font-family: 'Century Gothic';
        letter-spacing: 1px;
        width: 100%;
        margin-top: 5px;
    }
}

@media screen and (max-width:700px) {
    .home .hero .cadre{
        position: absolute;
        top: 35%;
        height: 360px;
        width: 550px;
        border: 20px solid rgb(62, 62, 255);
        border-left: none;
        border-top-right-radius: 100px; 
        border-bottom-right-radius: 5px;
    
    }
    .home .hero .heroTitle h1{
        text-shadow: 1px 1px 2px rgb(45, 45, 45);
        font-size: 40px;
        width: 100%;
    }
    .home .hero .heroTitle .bigTitle{
        text-shadow: 1px 1px 2px rgb(45, 45, 45);
        position: relative;
        top: -20px;
        font-size: 45px;
    }
    .home .hero .heroTitle{
        position: absolute;
        top: 60%;
        font-family: 'Century Gothic';
        color: white;
        width: 80%;
        left: 20px;
        max-width: 500px;
        z-index: 50;
    }
    .hero2 .hero2Container .quality{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
        flex-wrap: wrap;
    }
    .hero2 .hero2Container .quality .box{
        width: 250px;
        height: 220px;
        border: 1px solid rgb(200, 200, 200);
        cursor: pointer;
        transition: 0.2s ease-in-out;
        margin-bottom: 10px;
    }
}
@media screen and (max-width:600px) {
    .hero2 .hero2Container .quality{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
        flex-wrap: wrap;
    }
    .formContainer h2{
        font-size: 30px;
        font-weight: 800;
        font-family: 'Century Gothic';
        width: 90%;
        margin-left: 9%;
        margin-bottom: 30px;
        text-align: left;
    
    }
    .projects .projectsContainer2 .image{
        width: 90%;
        height: 320px;
    }
    .projects .projectsContainer2 .image img{
        width: 100%;
        height: 320px;
    }
    .hero2 .hero2Container .quality .box{
        width: 250px;
        height: 220px;
        border: 1px solid rgb(200, 200, 200);
        cursor: pointer;
        transition: 0.2s ease-in-out;
        margin-bottom: 10px;
    }
    .hero2 .hero2Container button{
        border: 2px solid rgb(49, 49, 49);
        background-color: white;
        color: rgb(49, 49, 49);
        transition: 0.2s ease-in-out;
        width: 250px;
        height: 43px;
        font-family: 'Century Gothic';
        margin-top: 20px;
        font-weight: 600;
        font-size: 17px;
    }
    .home .hero .cadre{
        position: absolute;
        top: 35%;
        height: 360px;
        width: 450px;
        border: 20px solid rgb(62, 62, 255);
        border-left: none;
        border-top-right-radius: 100px; 
        border-bottom-right-radius: 5px;
    
    }
    .part .secondVid{
        width: 100%;
        display: flex;
    }
    
    .hero video{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .part img{
        display: none;
    }
    .hero2 .hero2Container p span{
        color: white;
        font-size: 30px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: bold;
        color: rgb(0, 57, 148);
        text-shadow: 0px 0px 0px rgb(29, 29, 29);
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        letter-spacing: 0px;
    
    }
    .hero2 .hero2Container p{
        color: black;
        font-size: 20px;
        font-family: 'Century Gothic';
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
    .part{
        width: 100%;
        margin-bottom: 80px;
        display: block;
    }
    .part1{
        display: block;
    }
}

@media screen and (max-width:500px) {
    .hero2 .hero2Container h3{
        color: rgb(62, 62, 62);
        font-size: 30px;
        font-family: 'Century Gothic';
        letter-spacing: 1px;
        width: 100%;
        margin-top: 5px;
    }
    .hero .video{
        position: absolute;
        width: 100%; 
        height: 100%;
        object-fit: fill;
        display: none;

    }
    .hero .video2{
        position: absolute;
        width: 100%; 
        height: 100%;
        object-fit: fill;
        display: block;
        top: 0;
    }
    .hero{
        width: 100%;
        position: relative;
        height: 270px;
        top: -0px;
        margin-bottom: 10px;
    }
    .hero2{
        width: 100%;
        min-height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 60px;
        margin-top: 0px;
    }
    .part .secondImg{
        display: none;
    }
    .home .hero .heroTitle{
        position: absolute;
        top: 48%;
        font-family: 'Century Gothic';
        color: white;
        width: 80%;
        left: 20px;
        max-width: 650px;
        z-index: 50;
        display: none;
    }
    .home .hero .video2{
        object-fit: fill;
    }
    .home .hero .heroTitle button{
        background-color: aliceblue;
    }
    .home .hero .heroTitle h1{
        text-shadow: 1px 1px 2px rgb(45, 45, 45);
        font-size: 30px;
        width: 100%;
    }
    .home .hero .heroTitle .bigTitle{
        text-shadow: 1px 1px 2px rgb(45, 45, 45);
        position: relative;
        top: -20px;
        font-size: 35px;
    }
    .home .hero .upperHero{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(185, 185, 185, 0.16);
    }
}
@media screen and (max-width:480px) {
 
    .projects .porject .projectImg{
        width: 100%;
        height: 320px;
        background-color: rgb(205, 205, 205);
        position: relative;
        margin-bottom: 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .projects .porject .projectImg img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .formBox form .frm{
        width: 95%;
        padding-left: 30px;
    }
    .formBox form .frm h4{
        margin-bottom: 20px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 22px;
    }
    .formBox form input{
        display: block;
        width: calc(100% - 50px);
        height: 26px;
        margin-bottom: 15px;
        padding-left: 5px;
        outline: none;
        border: 1px solid rgb(199, 199, 199);
    
    }
    .home .hero .cadre{
        position: absolute;
        top: 35%;
        height: 340px;
        width: 400px;
        border: 20px solid rgb(62, 62, 255);
        border-left: none;
        border-top-right-radius: 100px; 
        border-bottom-right-radius: 5px;
    
    }
}
@media screen and (max-width:480px) {
    .title .texting .text{
        width: 100%;
    }
    .title .texting .text img {
        width: 100%;
        height: 190px;
        border-radius: 5px;
        margin-right: 0px;
        object-fit: cover;
    }
    .projects .projectsContainer2 .image{
        width: 90%;
        height: 300px;
    }
    .projects .projectsContainer2 .image img{
        width: 100%;
        height: 300px;
    }
    
}
@media screen and (max-width:415px) {
 
    .projects .projectsContainer2 .image{
        width: 90%;
        height: 280px;
    }
    .projects .projectsContainer2 .image img{
        width: 100%;
        height: 280px;
    }
    
    .projects .porject .projectImg{
        width: 100%;
        height: 280px;
        background-color: rgb(205, 205, 205);
        position: relative;
        margin-bottom: 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .projects .porject .projectImg img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .porject .icons .qualite img{
        width: 30px;
        height: 30px;
        margin-left: 5px;
    }
    .home .hero .cadre{
        position: absolute;
        top: 36%;
        height: 320px;
        width: 350px;
        border: 20px solid rgb(62, 62, 255);
        border-left: none;
        border-top-right-radius: 100px; 
        border-bottom-right-radius: 5px;
    
    }
    .formBox form .frm {
        width: 93%;
        padding-left: 30px;
    }
}
