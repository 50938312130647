.nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
.navContainer{
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    z-index: 100;

}
.nav img{
    margin-left: 10px;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    margin-top: 5px;
    display: none;
}
.nav .burger{
    width: 60px;
    cursor: pointer;
    display: block;
    margin-right: 10px;
}
.navContainer .menu2{
    position: fixed;
    height: 0%;
    overflow: hidden;
    transition: 1s;
    top: 0;
    width: 100%;

}
.menuHeader{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.close{
    width: 30px;
    margin-right: 10px;
    margin-top: 10px;
}
.navContainer .menu{
    width: 100%;
    background-color: orange;
    height: 100vh;
    position: fixed;
    top: 0;
    transition: 1s;
    overflow: hidden;
}
.navContainer .menu .menuBody{
    margin-top: 18%;
}
.navContainer .menu2 .menuBody{
    margin-top: 40%;
}
.navContainer .menu .menuBody h2{
    margin-bottom: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 35px;
}
.navContainer .menu .menuBody p{
    margin-bottom: 8px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    cursor: pointer;
}

.navContainer .menu2 .menuBody h2{
    margin-bottom: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 30px;
}
.navContainer .menu2 .menuBody p{
    margin-bottom: 8px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width:600px) {
    .navContainer .menu .menuBody{
        margin-top: 40%;
    }
}

@media screen and (max-width:450px) {
    .navContainer .menu{
        width: 100%;
        background-color: orange;
        height: 120vh;
        position: fixed;
        top: 0;
        transition: 1s;
        overflow: hidden;
    }
}
