*{
  margin: 0;
  padding: 0;
}
body {
  background-color: rgb(255, 255, 255);
  scroll-behavior: smooth;
}


