.admin{
    width: 100%;
    z-index: 105;
    position: relative;
    background-color: white;
}
.loginBox{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}
.loginContainer{
    width: 500px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(201, 201, 201);
    box-shadow: 2px 2px 5px rgb(180, 180, 180);
    margin-top: 100px;
    display: block;
    height: fit-content;
    padding-bottom: 50px;
}
.loginContainer h1{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
}
.loginContainer input{
    width: calc(80% - 10px);
    margin-bottom: 10px;
    border-radius: 100px;
    height: 30px;
    border: 1px solid gray;
    outline: none;
    padding-left: 5px;
    padding-right: 5px;
}
.loginContainer .label{
    width: calc(80% - 10px);
    text-align: left;
    margin-bottom: 5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.loginContainer button{
    width: calc(80% - 10px);
    border-radius: 100px;
    height: 32px;
    margin-top: 5px;
    border: 1px solid rgb(170, 170, 170);
    background-color: rgb(24, 150, 192);
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 18px;
    cursor: pointer;
}









.admin .heading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info{
    display: flex;
    align-items: center;
    width: 230px;
    justify-content: space-between;
}
.info .name{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
    width: fit-content;
    border-right: 1px solid black;
    padding-right: 15px;
}
.info2{ 
    display: flex;
    width: 250px;
    justify-content: space-evenly;
}
.admin .heading .head{ 
    width: 95%;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid rgb(159, 159, 159);
}
.admin .heading .head button{
    background-color: red;
    color: white;
    width: 80px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid gray;
}
.admin .users{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin .users .usersContainer{
    width: 95%;
    background-color: white;
    margin-top: 10px;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid rgb(159, 159, 159);
}
.admin .users .usersContainer .email{
    background-color: rgb(255, 255, 255);
    height: fit-content;  
    padding: 5px;
    padding-left: 0;
    padding-right: 0;
    margin: 5px;
    border-radius: 0px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    border-bottom: 1px solid rgb(185, 185, 185);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.emailInfos{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.adr{
    width: 280px;
    background-color: rgb(0, 149, 255);
    border-radius: 2px;
    padding-left: 5px;
    height: 25px;
}
.adrr2{
    width: 180px;
    background-color: rgb(0, 149, 255);
    border-radius: 2px;
    padding-left: 5px;
    height: 25px;
    margin-left: 5px;
    font-size: 15px;
}
.date{
    width: 120px;
    background-color: rgb(0, 149, 255);
    border-radius: 2px;
    margin-left: 8px;
    text-align: center;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.adr2{
    width: 280px;
    background-color: rgb(255, 174, 0);
    border-radius: 2px;
    padding-left: 5px;
    height: 25px;
    text-align: center;
}
.adr22{
    width: 180px;
    background-color: rgb(255, 174, 0);
    border-radius: 2px;
    padding-left: 5px;
    height: 25px;
    text-align: center;
    margin-left: 5px;
}
.date2{
    width: 120px;
    background-color: rgb(255, 174, 0);
    border-radius: 2px;
    margin-left: 8px;
    text-align: center;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin .users .usersContainer .email button{
    width: 30px;
    height: 20px;
    background-color: red;
    color: white;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 3px;
    cursor: pointer;
}
.searchInput{
    margin-left: 10px;
    width: 250px;
    height: 22px;
    padding-left: 5px;
}
.searchInput2{
    margin-left: 10px;
    width: 150px;
    height: 22px;
    padding-left: 5px;
}
.admin .message{
    background-color: rgba(148, 148, 148, 0.387);
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.choiceButtons{
    text-align: end;
    margin-top: 10px;
}
.choiceButtons button{
    background-color: rgb(0, 115, 255);
    width: 60px;
    height: 25px;
    margin-left: 5px;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    border: 1px solid rgb(0, 100, 199);
}
.messageBox{
    background-color: white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgb(104, 104, 104);
    border: 1px solid gray;
    
}