.page{
    width: 100%;
    display: flex;
    justify-content: center;
}
.pageContainer{
    width: 90%;
    margin-top: 100px;
}
.pageContainer h2{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 20px;
    margin-left: 6%;
}
.pageImages{
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
    flex-wrap: wrap;
}
.pageImages img{
    width: 300px;
    height: 200px;
    object-fit: fill;
    margin: 5px;
    cursor: pointer;
    box-shadow: 2px 2px 6px rgb(173, 173, 173);
    margin-bottom: 20px;
}
.showedImage{
    width: 100%;
    height: 100vh;
    background-color: rgba(50, 50, 50, 0.438);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showedImageContainer{
    width: 500px;
    height: 400px;
    background-color: rgba(183, 183, 183, 0.692);
    position: relative;

}
.showedImageContainer .but1{
    position: absolute;
    top: 50%;
    left: 1px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: rgb(0, 119, 255);
    border: none;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}
.showedImageContainer .but2{
    position: absolute;
    top: 50%;
    right: -1px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: rgb(0, 119, 255);
    border: none;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.showedImageContainer .exit{
    position: absolute;
    right: -10px;
    top: -10px;
    color: white;
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    padding: 5px;
    width: 15px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: black;
    font-size: 25px;
    padding-top: 0;
    cursor: pointer;
}
.showedImageContainer .img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border: 1px solid rgb(160, 160, 160);
    animation-name: imageAnimation;
    animation-duration: 0.5s;
}
@keyframes imageAnimation {
    from{
        opacity: 0;
        transform: scale(0);
    }to{
        opacity: 1;
        transform: scale(1);
    }
}
.but1 img{
    width: 30px;
    height: 30px;
}
.but2 img{
    width: 30px;
    height: 30px;
}
@media screen and (max-width:550px){
    .showedImageContainer{
        width: 350px;
        height: 320px;
        position: relative;
    
    }
    .showedImageContainer .but1{
        position: absolute;
        top: 50%;
        left: 1px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: rgb(0, 119, 255);
        border: none;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    .showedImageContainer .but2{
        position: absolute;
        top: 50%;
        right: -1px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: rgb(0, 119, 255);
        border: none;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }
    .but1 img{
        width: 25px;
        height: 25px;
    }
    .but2 img{
        width: 25px;
        height: 25px;
    }
}
@media screen and (max-width:400px){
    .showedImageContainer{
        width: 320px;
        height: 300px;
        position: relative;
    
    }
}
@media screen and (max-width:340px){
    .showedImageContainer{
        width: 280px;
        height: 250px;
        position: relative;
    
    }
}